import {
  FETCH_USERS,
  SET_LOADING,
  FETCH_USER,
  FETCH_REPOS,
  SEARCH_USER,
} from "./Types";

const githubReducer = (state, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_USERS:
      return {
        ...state,
        users: action.payload,
        isLoading: false,
      };
    case FETCH_USER:
      return {
        ...state,
        user: action.payload,
        isLoading: false,
      };
    case FETCH_REPOS:
      return {
        ...state,
        repos: action.payload,
        isLoading: false,
      };
    case SEARCH_USER:
      return {
        ...state,
        users: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};
export default githubReducer;
