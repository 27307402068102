import React from "react";

export default function About() {
  const techniques = [
    "userState",
    "useEffect",
    "React Router",
    "useContext",
    "Reducer and Actions",
    "Axios with async and await",
    "useReducer and actions",
    "HOC"
  ];

  const renderTech = () => {
    return techniques.map((tech) => (
      <button className="btn btn-info m-3">{tech}</button>
    ));
  };
  return (
    <div className="container mt-3">
      <div className="row">
        <div className="col">
          <h3>About this Site</h3>
          <p>App to search github users</p>
          <p>v1.0.0</p>
          <h3>Technique stack used:</h3>
          <div className="d-flex flex-wrap mt-4">{renderTech()}</div>
        </div>
      </div>
    </div>
  );
}
