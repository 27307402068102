import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Repos from "../Components/Repos";
import WithContext from "../Context/WithContext";

const Detail = ({ state, actions, match }) => {
  const login = match.params.login;
  const {
    avatar_url,
    location,
    bio,
    html_url,
    name,
    company,
    followers,
    following,
    public_repos,
    public_gists,
  } = state.user;

  useEffect(() => {
    actions.fetchUser(login);
    actions.fetchRepos(login);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="container p-5">
      <Link to="/" className="btn btn-info">
        {" "}
        Back to Search
      </Link>
      <div className="row p-5">
        <div className="col-md-6 text-center align-self-center">
          <img
            src={avatar_url}
            alt=""
            className="rounded-circle mb-3"
            width="150px"
            height="150px"
          />
          <p className="lead">Location: {location}</p>
        </div>
        <div className="col-md-6">
          <h3>Bio</h3>
          <p>{bio}</p>
          <a className="btn btn-dark my-3" href={html_url}>
            Visit Github Profile
          </a>
          <p>Username: {name}</p>
          <p>Company: {company}</p>
        </div>
      </div>
      <div className="row">
        <div className="col text-center">
          <div className="p-2 mx-2 badge badge-danger">
            Folowers: {followers}
          </div>
          <div className="p-2 mx-2 badge badge-success">
            Following: {following}
          </div>
          <div className="p-2 mx-2 badge badge-light">
            Public Repos: {public_repos}
          </div>
          <div className="p-2 mx-2 badge badge-dark">
            Public Gists: {public_gists}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Repos repos={state.repos}></Repos>
        </div>
      </div>
    </div>
  );
};

export default WithContext(Detail);
