import React from 'react'

export default function NotFound() {
  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col">
          <p>Ooooooops, the page is not found.</p>
        </div>
      </div>
    </div>
  )
}
