import React from 'react'
import spinner from '../images/spinner.gif'

export default function Spinner() {
  return (
    <div className="container text-center">
      <img src={spinner} alt="Loading" ></img>
    </div>
  )
}
