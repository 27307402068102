import React from "react";

export default function Repos({ repos }) {
  const renderRepos = () => {
    return repos.map((repo) => (
      <div key={repo.id} className="m-3">
        <a className="btn btn-info" href={repo.html_url}>
          {repo.name}
        </a>
      </div>
    ));
  };

  return (
    <div className="mt-5">
      <h4>Repos:</h4>
      <div className="d-flex flex-wrap">
      {renderRepos()}
      </div>
    </div>
  );
}
