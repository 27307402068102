import React from "react";
import { Link } from "react-router-dom";

const UserListItem = (props) => {
  const { user } = props;
  return (
    <div className="card">
      <div className="card-body text-center">
        <img
          src={user.avatar_url}
          className="rounded-circle"
          style={{ width: "75px", height: "75px" }}
          alt="User Avatar"
        />
        <p className="lead">{user.login}</p>
        <Link to={`/detail/${user.login}`} className="btn btn-dark">
          More
        </Link>
      </div>
    </div>
  );
};

export default UserListItem;
