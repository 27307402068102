import React from "react";
import Search from "../Components/Search";
import UserList from "../Components/UserList";
import WithContext from '../Context/WithContext'

const Home = () => {
  
  return (
    <React.Fragment>
      <Search></Search>
      <UserList /> 
    </React.Fragment>
  );
};

export default WithContext(Home);
