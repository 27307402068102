import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./Components/Header";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Detail from "./Pages/Detail";
import GithubContext from "./Context/GithubContext";
import NotFound from "./Pages/NotFound";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

const App = () => {
  return (
    <GithubContext>
      <Router>
        <Header></Header>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/about" component={About} />
          <Route exact path="/detail/:login" component={Detail} />
          <Route component={NotFound} />
        </Switch>
      </Router>
    </GithubContext>
  );
};

export default App;
