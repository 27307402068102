import React from "react";
import AppContext from "./AppContext";
import GithubReducer from "./GithubReducer";
import {
  FETCH_USERS,
  SET_LOADING,
  FETCH_USER,
  FETCH_REPOS,
  SEARCH_USER,
} from "./Types";
import axios from "axios";

const GithubContext = (props) => {
  const initialState = {
    users: [],
    user: {},
    repos: [],
    alertMsg: "",
  };

  const [state, dispatch] = React.useReducer(GithubReducer, initialState);

  let githubUserFinderId;
  let githubUserFinderSecret;
  if (process.env.NODE_ENV !== "production") {
    githubUserFinderId = process.env.REACT_APP_GITHUB_USER_FINDER_ID;
    githubUserFinderSecret = process.env.REACT_APP_GITHUB_USER_FINDER_SECRET;
  } else {
    githubUserFinderId = process.env.GITHUB_USER_FINDER_ID;
    githubUserFinderSecret = process.env.GITHUB_USER_FINDER_SECRET;
  }

  const setLoading = () => {
    dispatch({
      type: SET_LOADING,
    });
  };
  // fetch Users
  const fetchUsers = async () => {
    setLoading();
    const response = await axios.get(
      `https://api.github.com/users?client_id=${githubUserFinderId}&client_secret=${githubUserFinderSecret}`
    );
    dispatch({
      type: FETCH_USERS,
      payload: response.data,
    });
  };

  //fetchUser
  const fetchUser = async (login) => {
    setLoading();
    const response = await axios.get(
      `https://api.github.com/users/${login}?client_id=${githubUserFinderId}&client_secret=${githubUserFinderSecret}`
    );
    dispatch({
      type: FETCH_USER,
      payload: response.data,
    });
  };

  //fetch repos
  const fetchRepos = async (login) => {
    setLoading();
    const response = await axios.get(
      `https://api.github.com/users/${login}/repos?client_id=${githubUserFinderId}&client_secret=${githubUserFinderSecret}`
    );
    dispatch({
      type: FETCH_REPOS,
      payload: response.data,
    });
  };

  // Search User
  const onSearch = async (searchKey) => {
    setLoading();
    const response = await axios.get(
      `https://api.github.com/search/users?q=${searchKey}&client_id=${githubUserFinderId}&client_secret=${githubUserFinderSecret}`
    );
    dispatch({
      type: SEARCH_USER,
      payload: response.data.items,
    });
  };

  return (
    <AppContext.Provider
      value={{
        state: state,
        actions: { fetchUsers, fetchUser, fetchRepos, onSearch },
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default GithubContext;
