import React from 'react'
import {Link} from 'react-router-dom'

export default function Header() {
  return (
    <nav className="navbar navbar-expand-sm navbar-light bg-light">
    <div className="container">
      <Link className="navbar-brand" to="/">
        <i className="fa fa-github"></i> Github User Finder
      </Link>
      <ul className="navbar-nav ml-auto">
        <li key={1} className="nav-item">
          <Link to="/" className="nav-link">
            Home
          </Link>
        </li>
        <li key={2} className="nav-item">
          <Link to="/about" className="nav-link">
            About
          </Link>
        </li>
      </ul>
    </div>
  </nav>
  )
}
