import React, {useEffect} from 'react'
import UserListItem from "../Components/UserListItem";
import Spinner from "./Spinner";
import WithContext from '../Context/WithContext';

const UserList = ({state, actions}) => {

  useEffect(()=>{
    actions.fetchUsers()
    // eslint-disable-next-line
  }, [])


  const renderUsers = () => {
    return state.users.map(user => (
      <div key={user.id} className="col-md-3 mt-3">
        <UserListItem  user={user} /> 
      </div>
    ))
  };

  return (
    <div className="container">
      <div className="row">
        {state.isLoading ? <Spinner /> : renderUsers()}
      </div>
    </div>
  )
}

export default WithContext(UserList)