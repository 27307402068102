import React, { useState } from "react";
import WithContext from "../Context/WithContext";

const Search = ({ state, actions }) => {
  const [searchKey, setSearchKey] = useState("");
  const [alertMsg, setAlertMsg] = useState("");

  //Set Alert
  const setAlert = (msg) => {
    if (msg) {
      setAlertMsg(msg);
      setTimeout(() => {
        setAlertMsg("");
      }, 5000);
    }
  };

  const onSubmit = (event) => {
    event.preventDefault();
    if (!searchKey) {
      setAlert("User name can not be empty");
      return;
    }
    try{
      actions.onSearch(searchKey);
      setSearchKey("");
    }
    catch(err) {
      setAlert("There is an issue fethching data", err)
    }
  };

  const onChange = (event) => {
    setSearchKey(event.target.value);
  };

  return (
    <div className="container py-4">
      {alertMsg && (
        <div className="alert alert-danger" role="alert">
          {alertMsg}
        </div>
      )}
      <form onSubmit={onSubmit}>
        <input
          type="text"
          name="searchKey"
          className="form-control mb-3"
          placeholder="Type in User"
          value={searchKey}
          onChange={onChange}
        />
        <input
          type="submit"
          className="btn btn-dark btn-block mb-3"
          value="Search"
        ></input>
      </form>
    </div>
  );
};

export default WithContext(Search);
